
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "CoreView",
  data() {
    return {
      policyCompnent: false,
    };
  },
  components: {
    CoreFooter: () => import("./Footer.vue"),
    PrivacyPolicy: () => import("./PrivacyPolicy.vue"),
  },
  computed: {
    ...mapGetters({
      user: "getLoggedUser",
    }),
  },
  methods: {
    CheckPolicy(): void {
      // record the user details
      if (!this.$cookies.get("user-lgpd-accept") && !this.user.isAnonymous)
        this.policyCompnent = true;
    },
  },
  created() {
    this.CheckPolicy();
  },
});
